@import '../../../styles/customMediaQueries.css';
.searchListingsPanel {
  width: 100%;
  margin: auto;
  max-width: var(--contentMaxWidthPages);
  padding: 0 32px 0 32px;

  img {
    border-radius: 8px;
  }
}

.searchResultSummary {
  padding-top: 40px;
  margin: 10px 8px 19px 0;
}

.listingHeader{
  display: flex; 
  justify-content: start;  
  padding: 32px 0 0;
}
@media (--viewportMedium) {
  .listingHeader{
    justify-content: center;
    padding: 50px 0 0;
  }
}